import './progressContainer.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export const Errors = (props: {errors: number }) => {
  if (props.errors === 1) {
    return <div className="Progress_Title_errors">{props.errors} error</div>
  } else if (props.errors > 1) {
    return <div className="Progress_Title_errors">{props.errors} errors</div>
  }
  return <div/>
}

export const Cancels = (props: {cancelled: number }) => {
  if (props.cancelled === 1) {
    return <div className="Progress_Title_cancels">{props.cancelled} cancellation</div>
  } else if (props.cancelled > 1) {
    return <div className="Progress_Title_cancels">{props.cancelled} cancellations</div>
  }
  return <div/>
}

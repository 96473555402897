import './progressContainer.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ProgressBar } from 'react-bootstrap'

const ProgressBarWrapper = (props: { progress: number, state: string }) => {
  switch (props.state) {
    case 'error':
    case 'fatal':
      return <ProgressBar className="ProgressBar_error" variant="success" now={props.progress} />;
    case 'success':
      return <ProgressBar className="ProgressBar_success" variant="success" now={props.progress} />;
    case 'inProgress':
    default:
      return <ProgressBar className="ProgressBar_progress" variant="success" now={props.progress} />;
  }
}

export default ProgressBarWrapper;

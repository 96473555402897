import React, { useEffect } from "react";
import "./progressContainer.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { retrySendToBluescape, cancelVersionUpload, sendLogsToBackend, getTransferItemsFailedToInitiate } from "../../actions/api";
import CanvasButton from "./canvasButton";
import ProgressBar from "./progressBar";
import ProgressIcon from "./progressIcon";
import { Cancels, Errors } from "./progressHelpers";
import ReactTooltip from "react-tooltip";
import { uploadFailErrorMapping } from "../error/error";
import { LogTypes, TransferItemInfo } from "../../types/types";
import { AxiosResponse } from "axios";

// TODOS:
// 1. Allow X button before upload has initiated (while it's in waiting state). Need logic for resizing canvas first: CE-831
// 2. Do we want to send log to backend every time a subscription informs us of successful upload into workspace?

interface ProgressInfo {
    name: string;
    transferState?: string;
    id: string; // version id
    elementId?: string;
    progress?: number;
    message?: string;
    errorMessage?: string;
    state?: string;
    supported: boolean;
}

interface Props {
    transferId: string;
    orgId: string;
    orgName: string;
    initialUploadList: ProgressInfo[];
    wsId: string;
    wsName: string;
    entityType: string;
    entityName: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    canvasData: any;
    canvasLoading: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    progressData: any;
    progressLoading: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ProgressList = (props: { entityName: string; initialUploadList: ProgressInfo[]; orgId: string; wsId: string; transferId: string; progressData: any }) => {
    // States
    const [progressList, setProgressList] = React.useState([
        {
            name: "",
            progress: 0,
            transferState: "",
            id: "",
            message: "",
            errorMessage: "",
            state: "",
            elementId: "",
            supported: false,
        },
    ]);

    const [progressSummary, setProgressSummary] = React.useState({
        percentage: 0,
        complete: 0,
        errors: 0,
        cancelled: 0,
        all: 0,
        status: "Waiting",
    });

    const uninitiatedTimer = React.useRef<ReturnType<typeof setTimeout> | null>(null);
    const [isUninitiatedTimerExecuting, setIsUninitiatedTimerExecuting] = React.useState(false);

    // Effects

    // initial upload list
    useEffect(() => setProgressList(props.initialUploadList.map((i) => mapToTransferState(i))), [props.initialUploadList]);

    // subscription messages
    useEffect(() => {
        if (uninitiatedTimer.current && !isUninitiatedTimerExecuting) {
            // If a timer is set but not running, clear it
            clearTimeout(uninitiatedTimer.current);
        }

        const data = props.progressData;
        if (
            data?.commands?.commandType === "CreateElementCommand" &&
            data.commands.element?.id &&
            data.commands.element.ingestionState &&
            data.commands.element.traits
        ) {
            const elementId = data.commands.element.id;
            const transferState = data.commands.element.ingestionState;
            const transferId = data.commands.element.traits["http://bluescape.dev/transfer/v1/transferId"];
            const id = data.commands.element.traits["http://bluescape.dev/transfer/v1/entityId"];
            if (transferId && transferId === props.transferId && id) {
                updateProgressListElement({
                    id,
                    transferState,
                    elementId,
                });
            }
        } else if (
            data?.commands?.commandType === "UploadAssetCommand" &&
            (data.commands.elementId || data.commands.versionId) &&
            data.commands.ingestionState
        ) {
            const updatedElementKeys: Partial<ProgressInfo> = {}; // the keys we'll be updating for an element whose subscription event info has changed
            updatedElementKeys.elementId = data.commands.elementId;
            updatedElementKeys.transferState = data.commands.ingestionState;

            // Determine the versionId of the element being updated
            if (!data.commands.versionId && data.commands.elementId) {
                // Lookup the versionId based on the elementId
                const versionId = lookupVersionId(data.commands.elementId);
                if (versionId) {
                    updatedElementKeys.id = versionId;
                }
            } else if (data.commands.versionId) {
                // Assign the supplied versionId (comes via internal call - Failed to initiate upload)
                updatedElementKeys.id = data.commands.versionId;
            }

            if (data.commands.ingestionState === "complete_failure") {
                const errorMessage =
                    data.commands.traits?.["http://bluescape.dev/zygote/v1/ingestionState"]?.["http://bluescape.dev/zygote/v1/ingestionState/errorMessage"] ??
                    undefined;
                if (errorMessage) {
                    const mappedErrorMessage = uploadFailErrorMapping(errorMessage);
                    updatedElementKeys.errorMessage = mappedErrorMessage;
                }
            }

            // Equivalency test passed to updateProgressListElement
            const equalElementIds = (element: ProgressInfo, updatedElement: Partial<ProgressInfo>): boolean => {
                if (element?.elementId && updatedElement?.elementId && element.elementId === updatedElement.elementId) {
                    return true;
                }
                if (!element.elementId && element?.id && updatedElement?.id && `${element.id}` === updatedElement.id) {
                    return true;
                }
                return false;
            };

            updateProgressListElement(updatedElementKeys, equalElementIds);
        }

        uninitiatedTimer.current = setTimeout(checkForUninitiatedUploads, 2500);

        // We do not want to depend on progressList as that causes update loops.
        // We only care here if the incoming subscription events should update the
        // progress info
    }, [props.progressData /*, progressList*/]);

    // Summary calculation
    useEffect(() => {
        const { totalProgress, complete, errors, cancelled } = progressList.reduce(
            (
                accumulator: { totalProgress: number; complete: number; errors: number; cancelled: number },
                current: { progress: number; transferState: string },
            ) => {
                accumulator.totalProgress += current.progress;
                if (["complete_failure", "unsupported"].includes(current.transferState)) {
                    accumulator.errors++;
                }
                if (["cancelled"].includes(current.transferState)) {
                    accumulator.cancelled++;
                }
                if (["complete_success", "complete_failure", "unsupported", "cancelled"].includes(current.transferState)) {
                    accumulator.complete++;
                }
                if (["retrying"].includes(current.transferState) && accumulator.complete > 0) {
                    // if manually retrying, decrement complete counter displayed on main progress bar
                    accumulator.complete--;
                }
                return accumulator;
            },
            {
                totalProgress: 0,
                complete: 0,
                errors: 0,
                cancelled: 0,
            },
        );

        const totalPercentage = clamp(Math.ceil(totalProgress / progressList.length), 0, 100);
        let status = "Waiting";
        if (totalProgress > 0) {
            status = complete === progressList.length ? "Upload complete" : "Uploading...";
        }

        setProgressSummary({
            percentage: totalPercentage,
            complete,
            errors,
            cancelled,
            all: progressList.length,
            status,
        });
    }, [progressList]);

    const lookupVersionId = (elementId: string): string | null => {
        const listItem = progressList.find((i) => i.elementId === elementId);
        return listItem?.id ?? null;
    };

    // Helper to update one element's status in the progress list
    const updateProgressListElement = (
        updatedElement: Partial<ProgressInfo>,
        comparisonFunc: (element: ProgressInfo, updatedElement: Partial<ProgressInfo>) => boolean = (
            element: ProgressInfo,
            otherElement: Partial<ProgressInfo>,
        ) => `${element.id}` === `${otherElement.id}`,
    ) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const list = progressList.map((element: any) => {
            if (comparisonFunc(element, updatedElement)) {
                // if we found the right one, lets apply the update and process the mapping
                if (updatedElement.transferState === "complete_failure" || updatedElement.transferState === "complete_success") {
                    sendLogsToBackend(props.transferId, {
                        logType: updatedElement.transferState === "complete_failure" ? LogTypes.UploadFailure : LogTypes.UploadSuccess,
                        logMessage:
                            updatedElement.transferState === "complete_failure" ? updatedElement.errorMessage ?? "Unknown error occurred" : "Upload Success",
                        organizationId: props.orgId,
                        versionId: element.id,
                        workspaceId: props.wsId,
                    }).catch((err) => {
                        console.error(`transferId: ${props.transferId} - Error sending logs to backend: `, err);
                    });
                }
                return mapToTransferState({
                    ...element,
                    ...updatedElement,
                });
            }
            return element;
        });
        setProgressList(list);
    };

    // Callback functions for the UI
    const cancelVersion = async (versionId: number) => {
        const elementId = progressList.find((i) => i.id === String(versionId))?.elementId ?? undefined; // get the elementId corresponding to this version in the progressList
        cancelVersionUpload(props.transferId, props.orgId, props.wsId, { versionId, elementId }).catch((err) =>
            console.error(`Unexpected error canceling upload: `, err),
        );
        updateProgressListElement({
            id: `${versionId}`,
            transferState: "cancelled",
            errorMessage: "",
        });
    };

    const retryVersion = async (versionId: number) => {
        retrySendToBluescape(props.transferId, props.orgId, props.wsId, { versionId }).catch((err) => {
            console.error(`Unexpected error retrying upload: `, err);
            updateProgressListElement({
                id: `${versionId}`,
                transferState: "complete_failure",
                errorMessage: "Failed to initiate upload",
            });
        });

        updateProgressListElement({
            id: `${versionId}`,
            transferState: "retrying",
            errorMessage: "",
        });
    };

    const checkForUninitiatedUploads = async () => {
        if (isUninitiatedTimerExecuting) {
            return;
        }

        setIsUninitiatedTimerExecuting(true);

        const someFilesAreWaiting = progressList.some((element) => element.state === "waiting");
        if (someFilesAreWaiting) {
            // Make REST api call to retrieve uploads that failed to initiate
            try {
                const failedItems: AxiosResponse<TransferItemInfo[]> = await getTransferItemsFailedToInitiate(props.transferId);

                for (const failedItem of failedItems.data) {
                    const listItem = progressList.find((i) => i.id === `${failedItem.id}` || i.id === failedItem.id);
                    if (listItem && listItem.state === "waiting") {
                        // Modify props.progressData to trigger UI change via Effect rather than call
                        // updateProgressListElement directly from here (which can produce odd results)
                        props.progressData = {
                            commands: {
                                commandType: "UploadAssetCommand",
                                versionId: `${failedItem.id}`,
                                ingestionState: "complete_failure",
                                traits: {
                                    ["http://bluescape.dev/zygote/v1/ingestionState"]: {
                                        ["http://bluescape.dev/zygote/v1/ingestionState/errorMessage"]: "Failed to initiate upload",
                                    },
                                },
                            },
                        };
                    }
                }
            } catch (err) {
                let code = "UnexpectedError";
                if (Array.isArray(err) && err.length > 0 && err[0]) {
                    code = err[0].errorCode;
                }
                console.error(`transferId: ${props.transferId} - failed while checking for uninitiated uploads with cause: ${code}`);
            }
        }

        setIsUninitiatedTimerExecuting(false);
    };

    return (
        <div>
            <div className="Total_progress_container">
                <div className="Progress_Header">
                    <span className="Progress_Total_Title">{props.entityName}</span>
                </div>
                <ProgressBar progress={progressSummary.percentage} state={progressSummary.status === "Upload complete" ? "success" : "inProgress"} />
                <div className="Progress_Title_number">
                    <div className="Progress_Title_status">
                        <span className="Progress_Title_progress">{progressSummary.status}</span>
                        {progressSummary.cancelled > 0 && <Cancels cancelled={progressSummary.cancelled} />}
                        {progressSummary.errors > 0 && <Errors errors={progressSummary.errors} />}
                    </div>
                    <div>
                        {progressSummary.complete}/{progressSummary.all}
                    </div>
                </div>
            </div>
            <div className="Progress_Container scrollbar scrollbar-primary">
                {progressList?.map((item, index) => (
                    <div key={`Progress_Wapper${index}`} className="Progress_Wapper">
                        <div className="Progress_Bar">
                            <div>
                                <div className="Progress_Header">
                                    <span className="Progress_Title">{item.name}</span>
                                    <div>
                                        {item.transferState === "complete_failure" && (
                                            <div className="Upload_Err_Icon" data-tip data-for={item.id}>
                                                <img src={require("../../assets/info.svg").default} alt="info" id={item.id} />
                                                <ReactTooltip
                                                    id={item.id}
                                                    className="popup-content"
                                                    getContent={() => {
                                                        if (item.errorMessage && item.errorMessage !== "") {
                                                            return item.errorMessage;
                                                        } else {
                                                            return "Unknown error occurred";
                                                        }
                                                    }}
                                                    place="top"
                                                    effect="solid"
                                                ></ReactTooltip>
                                            </div>
                                        )}
                                    </div>
                                    <div className="Progress_Message">{item.message}</div>
                                </div>
                                <ProgressBar progress={item.progress} state={item.state} />
                            </div>
                            <div>
                                <ProgressIcon id={item.id} state={item.state} retryFunc={retryVersion} cancelFunc={cancelVersion} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const ProgressContainer = (props: Props) => {
    return (
        <div>
            <div className="SelectForm_header_progress">Sending {props.entityType} to</div>
            <div className="List_name_header">
                {props.orgName} / {props.wsName}
            </div>

            <ProgressList
                entityName={props.entityName}
                initialUploadList={props.initialUploadList}
                orgId={props.orgId}
                wsId={props.wsId}
                transferId={props.transferId}
                progressData={props.progressData}
            />
            <CanvasButton wsId={props.wsId} transferId={props.transferId} data={props.canvasData} loading={props.canvasLoading} />
        </div>
    );
};

// Helpers

const clamp = (num: number, min: number, max: number) => {
    return num <= min ? min : num >= max ? max : num;
};

const mapToTransferState = (i: ProgressInfo) => {
    // Ok, so here we come up with high level progress and state information:
    // transferState      Progress  State               Message
    // waiting            0%        waiting             Waiting
    // retrying           0%        retrying            Retrying
    // transferring       35%       inProgress          Uploading...
    // processing         70%       inprogress          Processing...
    // complete_success   100%      success             Upload complete
    // complete_failure   100%      error               Upload failed (maybe details?)
    // unsupported        100%      error               Format not supported
    // cancelled          100%      error               Cancelled

    let message = "Waiting";
    let state = "inProgress";
    const elementId = i.elementId ?? "";
    let transferState = i.transferState ?? "waiting";
    const errorMessage = i.errorMessage ?? "";

    // Special handling for unsupported assets
    if (!i.supported) {
        transferState = "unsupported";
    }

    let progress = 0;
    switch (transferState) {
        case "waiting":
            progress = 0;
            message = "Waiting";
            state = "waiting";
            break;
        case "cancelled":
            progress = 100;
            message = "Cancelled";
            state = "error";
            break;
        case "transferring":
            progress = 35;
            message = "Uploading...";
            state = "inProgress";
            break;
        case "processing":
            progress = 70;
            message = "Uploading...";
            state = "inProgress";
            break;
        case "complete_success":
            progress = 100;
            message = "Upload complete";
            state = "success";
            break;
        case "complete_failure":
            progress = 100;
            message = "Upload failed";
            state = "error";
            break;
        case "unsupported":
            progress = 100;
            message = "Format not supported";
            state = "fatal";
            break;
        case "retrying":
            progress = 0;
            message = "Retrying";
            state = "retrying";
            break;
    }

    return {
        name: i.name,
        progress,
        transferState,
        id: i.id,
        message,
        errorMessage,
        state,
        elementId,
        supported: i.supported,
    };
};

export default ProgressContainer;

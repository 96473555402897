import { useSubscription } from '@apollo/client';
import { AxiosResponse } from 'axios';
import React, { useCallback, useEffect } from 'react';
import { Button, Spinner, Form, Modal } from 'react-bootstrap'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import {
  CANVAS_SUBSCRIPTION,
  createNewWorkspace,
  getGraphQLClient,
  getWorkspaceResults,
  getOrganizationInfo,
  getOrganizations,
  getSearchWorkspaceResults,
  getTransferInfo,
  getWorkspaceInfo,
  PROGRESS_SUBSCRIPTION,
  sendToBluescape } from './actions/api';
import DropdownMenu from './components/dropdown/dropdown';
import ProgressContainer from './components/progressContainer/progressContainer';
import Error, { workspaceCreateErrorMapping } from './components/error/error';
import {
  mapColorToString,
  mapStringToColor,
  QualifiedOrganizationInfoPayload,
  TransferInfoForOrganizationPayload,
  TransferInfoForWorkspaceResponse,
  TransferInfoPayload,
  TransferItemInfo,
  WorkspaceResults} from './types/types';


const App = () => {

  const sleep = (ms: number) => new Promise(r => setTimeout(r, ms)); // helper for displaying spinner during initial page load

  const [transferId, setTransferId] = React.useState('');

  const [initialTransferData, setInitialTransferData] = React.useState(
    {
      entityType: "",
      entityCount: 1
    }
  );

  const [extendedTransferData, setExtendedTransferData] = React.useState(
    {
      entityName: "",
      imageCount: 0,
      videoCount: 0,
      unsupportedCount: 0,
    }
  );

  const [organizationList, setOrganizationList] = React.useState(
    [
      {
        name: "",
        uid: "",
        defaultRoleId: "",
      }
    ]
  );

  const [readyToSendToBluescape, setReadyToSendToBluescape] = React.useState(false);
  const [sentToBluescape, setSentToBluescape] = React.useState(false);

  const [workspace, setWorkspaceList] = React.useState(
    [
      {
        name: "",
        uid: "",
        canEdit: false,
        hasWorkspaces: true
      }
    ]
  );

  const [workspaceData, setWorkspaceData] = React.useState(
    {
      proposedCanvasColor: "CanvasColorBluescape40",
      proposedCanvasName: "",
      previousCanvasCount: 0,
      canSend: true,
    }
  );

  const [dropdownNextCursor, setDropdownNextCursor] = React.useState(
    {
      organization: "",
      workspace: ""
    }
  );

  const [initialUploadList, setInitialUploadList] = React.useState(
    [
      {
        name: "",
        id: "",
        supported: true,
      }
    ]
  );

  const [selectedOrg, setSelectedOrg] = React.useState(
    {
      id: "",
      name: "",
      defaultRoleId: "",
    }
  );

  const [selectedWs, setSelectedWs] = React.useState(
    {
      id: "",
      name: "",
    }
  );

  const [selectedCanvas, setSelectedCanvas] = React.useState('');
  const [selectedCanvasColor, setSelectedCanvasColor] = React.useState('');

  const [showProgress, setShowProgress] = React.useState(false);

  const [errorState, setErrorState] = React.useState(
    {
      code: "",
    }
  );

  // Helper hooks for workspace create modal displayed to user
  const [showWsCreate, setShowWsCreate] = React.useState(false);
  const [wsCreateText, setWsCreateText] = React.useState({
    title: "",
    message: "",
  });

  // Note, the cause/code is not http status code, but Bluescape API code
  const handleFatalErrors = (useCallback((errors: { errorCode: string }[] ): void => {
    let code = "UnexpectedError";
    if (errors?.[0]) {
      code = errors[0].errorCode;
    }
    console.error(`transferId: ${transferId} - network request failed with cause: ${code}`);
    setErrorState({ code });
  }, [transferId]));

  // This hook kicks off everything
  useEffect(() => {
    trackPromise(sleep(500)).catch(err => console.log("Page initialization error")); // add .5s spinner to give page time to kick off

    const url = new URL(window.location.href);
    const id = url.searchParams.get("id");
    const err = url.searchParams.get("error"); // get errors sent from AMI controller

    if (err) { // Handle Errors sent via URL errors field
      setErrorState({ code: err});
      console.error(`Network request failed with cause: ${err}`);
    }
    else if (id) {
      setTransferId(id);
    }
  }, []);

  // Triggered when transferId updated, loads the initial transfer information
  useEffect(() => {
    if (transferId !== "") {
      trackPromise(
        getTransferInfo(transferId).then((res: AxiosResponse<TransferInfoPayload>) => {
          setInitialTransferData(res.data);
        })
      ).catch(err => handleFatalErrors(err.response?.data?.errors));
    }
  }, [handleFatalErrors, transferId]);

  // Handles loading list of available organizations
  useEffect(() => {
    if (initialTransferData.entityType !== "") {
      trackPromise(
        getOrganizations(transferId).then((res: AxiosResponse<QualifiedOrganizationInfoPayload[]>) => {
          setOrganizationList(res.data);
        })
      ).catch(err => handleFatalErrors(err.response?.data.errors));
    }
  }, [handleFatalErrors, initialTransferData, transferId]);

  // Handles loading more details based on the selected organization
  useEffect(() => {
    if (selectedOrg.id !== "") {
      trackPromise(
        getOrganizationInfo(transferId, selectedOrg.id).then((res: AxiosResponse<TransferInfoForOrganizationPayload>) => {
          setExtendedTransferData(res.data);
        })
      ).catch(err => handleFatalErrors(err.response?.data?.errors));
    }
  }, [handleFatalErrors, selectedOrg.id, transferId]);

  // Handles loading list of available workspaces
  useEffect(() => {
    if (selectedOrg.id !== "") {
      setInitialWorkspaces();
    }
  }, [handleFatalErrors, selectedOrg.id, transferId]);

  // Handles loading more details based on the selected workspace
  useEffect(() => {
    if (selectedWs.id !== "" && extendedTransferData.entityName !== "") {
      trackPromise(
        getWorkspaceInfo(transferId, selectedOrg.id, selectedWs.id).then((res: AxiosResponse<TransferInfoForWorkspaceResponse>) => {
          const data = res.data;
          const payload = {
            ...data,
            proposedCanvasColor: data.proposedCanvasColor ? mapColorToString(data.proposedCanvasColor) : "CanvasColorBluescape40",
          }
          setWorkspaceData(payload);
        })
      ).catch(err => handleFatalErrors(err.response?.data?.errors));
    }
  }, [handleFatalErrors, selectedOrg.id, extendedTransferData.entityName, selectedWs.id, transferId])


  // Create new workspaces, driven by Dropdown "Create new workspace" option
  const createWorkspace = (workspaceName: string) => {
    trackPromise(
      createNewWorkspace(transferId, selectedOrg.id, {"workspaceName": workspaceName, "defaultRoleId": selectedOrg.defaultRoleId})
      .then((res: AxiosResponse<string>) => {
        setWsCreateText({title: 'Workspace creation succeeded', message: `${workspaceName} created`});
        setSelectedWs({ id: res.data, name: workspaceName }); // select the new workspace after creation
        handleShowWsModal(); // show message to user indicating workspace creation success
      })
    ).catch(err => { 
      const errorMessage = workspaceCreateErrorMapping(err.response?.data?.errors[0]?.errorCode ?? "UnexpectedError"); // get user-friendly error mapping
      setWsCreateText({title: 'Workspace creation failed', message: `${errorMessage}`}) 
      resetSelectedWorkspace();
      handleShowWsModal(); // show message to user indicating workspace creation failure
    });
  }

  // Helpers for displaying modal for workspace creation user feedback
  const handleCloseWsModal = () => { // when closing ws modal, also delete the wsCreateText
    setShowWsCreate(false);
    setWsCreateText({title: "", message: ""}); 
  }
  const handleShowWsModal = () => setShowWsCreate(true);

  // Get the next set of workspaces, driven by scrolling down in the workspace dropdown list.
  const getNextWorkspaces = () => {
    resetSelectedWorkspace();
    trackPromise(
      getWorkspaceResults(transferId, selectedOrg.id, dropdownNextCursor.workspace)
      .then((res: WorkspaceResults) => {
        if (res.results.length > 0) {
          setWorkspaceList(workspace.concat(res.results));
        }
        setDropdownNextCursor({...dropdownNextCursor, workspace: res.nextCursor});
      })
    ).catch(err => handleFatalErrors(err.response?.data?.errors));
  }

  // Query for workspaces, driven by Dropdown Search functionality
  const queryWorkspace = (searchString: string) => {
    if(selectedOrg.id !== "") {
      resetSelectedWorkspace();
      trackPromise(
        getSearchWorkspaceResults(transferId, selectedOrg.id, searchString)
        .then((res: WorkspaceResults) => {
          if (res.results.length > 0) {
            setWorkspaceList(res.results);
          } 
          else {
            setWorkspaceList( [{
              name: "No workspaces",
              uid: "NOWORKSPACES",
              canEdit: false,
              hasWorkspaces: false
            }]);
          }
          setDropdownNextCursor({...dropdownNextCursor, workspace: res.nextCursor});
        })
      ).catch(err => handleFatalErrors(err.response?.data?.errors));
    }
  }

  // Reset back to the initial set of workspaces, driven by org switch, OR by Dropdown search bar being cleared.
  const setInitialWorkspaces = () => {
    if(selectedOrg.id !== "") {
      resetSelectedWorkspace();
      trackPromise(
        getWorkspaceResults(transferId, selectedOrg.id)
        .then((res: WorkspaceResults) => {
          if (res.results.length > 0) {
            setWorkspaceList(res.results);
          } else {
            setWorkspaceList( [{
              name: "No workspaces",
              uid: "NOWORKSPACES",
              canEdit: false,
              hasWorkspaces: false
            }]);
            resetSelectedWorkspace();
          }
          setDropdownNextCursor({...dropdownNextCursor, workspace: res.nextCursor});
        })
      ).catch(err => handleFatalErrors(err.response?.data?.errors));
    }
  }

  const onSelectOrganization = (orgId: string | null, name: string, defaultRoleId?: string) => {
    if(orgId && orgId !== selectedOrg.id) { // only select new org and reset canvas+workspace fields when new org was selected
      resetSelectedWorkspace();
      setSelectedOrg({ id: orgId, name: name, defaultRoleId: defaultRoleId ?? '' });
    }
  }

  const onSelectWorkspace = (wsId: string | null, name: string ) => {
    if (wsId) {
      setSelectedWs({ id: wsId, name });
    }
  }

  const onSendToBluescape = async () => {
    // This triggers GraphQL subscriptions for observing the
    // canvas creation and upload progress information. Once that is done
    // we actually invoke the API to send to bluescape
    setReadyToSendToBluescape(true);
  }

  const resetSelectedWorkspace = () => {
    setSelectedWs({
      id: "",
      name: "",
    });

    // Reset workspace name and canvas info when org is selected
    setWorkspaceData({
      proposedCanvasColor: "CanvasColorBluescape40",
      proposedCanvasName: "",
      previousCanvasCount: 0,
      canSend: true,
    });
  }

  const { data: canvasSubscriptionData, loading: canvasSubscriptionLoading } = useSubscription(
    CANVAS_SUBSCRIPTION,
    {
      variables: { workspace: selectedWs.id, transferId: transferId },
      client: getGraphQLClient(),
      skip: (!readyToSendToBluescape)
    }
  );

  const { data: progressSubscriptionData, loading: progressSubscriptionLoading } = useSubscription(
    PROGRESS_SUBSCRIPTION,
    {
      variables: { workspace: selectedWs.id },
      client: getGraphQLClient(),
      skip: (!readyToSendToBluescape)
    }
  );

  useEffect(() => {
    if (readyToSendToBluescape && !sentToBluescape) {
      setSentToBluescape(true);
      const color = selectedCanvasColor !== "" ? selectedCanvasColor : workspaceData?.proposedCanvasColor;
      const body = {
        canvasName: selectedCanvas !== "" ? selectedCanvas : workspaceData?.proposedCanvasName,
        canvasColor: mapStringToColor(color),
        versionCount: extendedTransferData.imageCount + extendedTransferData.videoCount + extendedTransferData.unsupportedCount,
      }

      trackPromise(
        sendToBluescape(
          transferId,
          selectedOrg.id,
          selectedWs.id,
          body
        ).then((result: AxiosResponse<TransferItemInfo[]>) => {
          setInitialUploadList(result.data);
          setShowProgress(true);
        })
      ).catch(err => handleFatalErrors(err.response?.data?.errors));
    }
    // Even though these are not used above, they are used to trigger this useEffect
  }, [canvasSubscriptionLoading, progressSubscriptionLoading])


  const EntityInfo = (props: { entityType: string, entityCount: number, entityName: string }) => {

    const { entityType, entityCount, entityName } = props;

    // EntityCount EntityTypes
    // or
    // EntityType "EntityName"
    if (entityCount && entityCount > 1) {
      return <div>{entityCount} {entityType}s</div>
    } else {
      if (entityName && entityName !== "") {
        return <div>{entityType} "{entityName}"</div>
      } else {
        return <div>{entityType}</div>
      }
    }
  }

  const FileInfo = (props: { imageCount: number, videoCount: number, unsupportedCount: number }) => {

    const { imageCount, videoCount, unsupportedCount } = props;
    const fileCount = imageCount + videoCount + unsupportedCount;

    let fileString = '';
    if (fileCount > 1) {
      fileString = `${fileCount} files`;
    } else if (fileCount === 1) {
      fileString = `1 file`;
    }

    let imageString = '';
    if (imageCount > 1) {
      imageString = `${imageCount} images`;
    } else if (imageCount === 1) {
      imageString = `1 image`;
    } else {
      imageString = `0 images`;
    }

    let videoString = '';
    if (videoCount > 1) {
      videoString = `${videoCount} videos`;
    } else if (videoCount === 1) {
      videoString = `1 video`;
    } else {
      videoString = `0 videos`;
    }

    let unsupportedString = '';
    if (unsupportedCount > 1) {
      unsupportedString = `${unsupportedCount} unsupported items`;
    } else if (unsupportedCount === 1) {
      unsupportedString = `1  unsupported item`;
    } else {
      unsupportedString = `0  unsupported items`;
    }

    if (fileCount > 0) {
      let contentString = `${fileString} (`;
      if (imageCount > 0) {
        contentString = `${contentString}${imageString}`;
      }
      if (videoCount > 0) {
        contentString = imageCount > 0 ? `${contentString} and ${videoString}` : `${contentString}${videoString}`;
      }
      if (unsupportedCount > 0) {
        contentString = (imageCount > 0 || videoCount > 0) ? `${contentString} and ${unsupportedString}` : `${contentString}${unsupportedString}`;
      }
      contentString = `${contentString})`;

      return <div>{contentString}</div>
    } else {
      return <div></div>
    }
  }

  const { promiseInProgress: blockInteraction } = usePromiseTracker({delay: 250});

  if (errorState?.code && errorState.code !== "") {
    return <Error errCode={errorState.code} />;
  } else {
    return (
      <div className="App" >
        {blockInteraction && <div className="Spinner_wrapper">
          <Spinner animation="border" role="status" variant="light">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
        }
        <div className="Container">
          <div className="Container_Title" >
            <img src={require('./assets/logo.png').default} alt="logo" />
            <h1>Send to Bluescape</h1>
            <EntityInfo entityCount={initialTransferData?.entityCount} entityType={initialTransferData?.entityType} entityName={extendedTransferData?.entityName}/>
            <FileInfo imageCount={extendedTransferData.imageCount} videoCount={extendedTransferData.videoCount} unsupportedCount={extendedTransferData.unsupportedCount} />
          </div>
          <div className="SelectForm">
            {(showProgress) ?
              <ProgressContainer transferId={transferId} orgId={selectedOrg.id} orgName={selectedOrg.name} wsId={selectedWs.id} wsName={selectedWs.name} initialUploadList={initialUploadList} entityType={initialTransferData?.entityType} entityName={extendedTransferData.entityName} canvasData={canvasSubscriptionData} canvasLoading={canvasSubscriptionLoading} progressData={progressSubscriptionData} progressLoading={progressSubscriptionLoading} />
              :
              <>
              <div className="SelectForm_header">
                Select destination - Add canvas
              </div>
                <DropdownMenu action={onSelectOrganization} items={organizationList} nextCursor={dropdownNextCursor.organization} placeholder="Select organization" title="ORGANIZATION" />
                <DropdownMenu action={onSelectWorkspace} actionNextResults={getNextWorkspaces} actionSearch={queryWorkspace} actionResetWorkspaces={setInitialWorkspaces} actionCreateWorkspace={createWorkspace} disabled={selectedOrg.id === ""} items={workspace} nextCursor={dropdownNextCursor.workspace} placeholder="Select workspace" title="WORKSPACE" />
                <Modal className="Workspace_Create_Modal" show={showWsCreate} onHide={handleCloseWsModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>{wsCreateText.title}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>{wsCreateText.message}</Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseWsModal}>
                    OK
                    </Button>
                  </Modal.Footer>
                </Modal>
                {(!workspaceData?.canSend) ?
                  <div className="WorkspaceAccess_error">
                    No edit access, please choose another workspace
                  </div>
                  :
                  <></>
                }
                <label htmlFor="test" className="Input_Title"> CANVAS NAME </label>
                <Form.Control
                  onChange={(e) => {
                    setSelectedCanvas(e.target.value)
                    setWorkspaceData({
                      ...workspaceData,
                      proposedCanvasName: e.target.value,
                    });
                  }}
                  disabled={selectedWs.id === "" || !workspaceData?.canSend}
                  type="text"
                  className="canvasInput"
                  placeholder="Enter canvas name"
                  value={(selectedWs.id !== "" && !workspaceData?.canSend) ? '' : workspaceData?.proposedCanvasName}
                />
                <label htmlFor="test" className="Input_Title"> CANVAS COLOR </label>
                <div className="color_wrapper" onChange={(data: React.ChangeEvent<HTMLDivElement>) => {
                  setSelectedCanvasColor(data.target.id);
                  setWorkspaceData({
                    ...workspaceData,
                    proposedCanvasColor: data.target.id as string
                  });

                }}>
                  <label>
                    <input type="radio" className="option-input radio canvas_color_blue" name="canvascolor" id="CanvasColorBluescape40" checked={workspaceData?.proposedCanvasColor === "CanvasColorBluescape40"} />
                  </label>
                  <label>
                    <input type="radio" className="option-input radio canvas_color_turtle" name="canvascolor" id="CanvasColorTurtleSoft" checked={workspaceData?.proposedCanvasColor === "CanvasColorTurtleSoft"} />
                  </label>
                  <label>
                    <input type="radio" className="option-input radio canvas_color_cheese" name="canvascolor" id="CanvasColorCheeseSoft" checked={workspaceData?.proposedCanvasColor === "CanvasColorCheeseSoft"} />
                  </label>
                  <label>
                    <input type="radio" className="option-input radio canvas_color_tangerine" name="canvascolor" id="CanvasColorTangerineSoft" checked={workspaceData?.proposedCanvasColor === "CanvasColorTangerineSoft"} />
                  </label>
                  <label>
                    <input type="radio" className="option-input radio canvas_color_sherbet" name="canvascolor" id="CanvasColorSherbetSoft" checked={workspaceData?.proposedCanvasColor === "CanvasColorSherbetSoft"} />
                  </label>
                  <label>
                    <input type="radio" className="option-input radio canvas_color_peony" name="canvascolor" id="CanvasColorPeonySoft" checked={workspaceData?.proposedCanvasColor === "CanvasColorPeonySoft"} />
                  </label>
                  <label>
                    <input type="radio" className="option-input radio canvas_color_grape" name="canvascolor" id="CanvasColorGrapeSoft" checked={workspaceData?.proposedCanvasColor === "CanvasColorGrapeSoft"} />
                  </label>
                </div>
                <Button className="Send_to_Bluescape" disabled={!selectedOrg || !selectedWs || workspaceData?.proposedCanvasName === "" || !workspaceData?.canSend} onClick={onSendToBluescape}>Send to Bluescape</Button>

              </>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default App;

import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect } from 'react';
import {Button} from 'react-bootstrap';

import {getDomain} from '../../actions/api';
import './error.css';
import { FatalErrorType } from '../../types/types'

const Error = (props: { errCode: string }) => {

  const [errorState, setErrorState] = React.useState(
    {
      code: "",
      message: "",
    }
  );

  // Note, the code is not http status code, but Bluescape API code
  const setErrorFromCode = ((code = "UnexpectedError" ) => {
    switch (code) {
      case "MultiSelectionNotSupportedForEntityTypeError":
        setErrorState({ code, message: FatalErrorType.MultipleEntities });
        break;
      case "ErrorTransferNotFound":
      case "ErrorTransferExpired":
      case "ErrorUnauthorizedAlreadyClaimed":
        setErrorState({ code, message: FatalErrorType.TransferUnavailable });
        break;
      case "ErrorNoQualifiedOrganizationsFound":
        setErrorState({ code, message: FatalErrorType.NoOrganizations });
        break;
      case "InvalidPayloadError":
        setErrorState({ code, message: FatalErrorType.InvalidPayloadError });
        break;
      case "NoSelectedIdsError":
        setErrorState({ code, message: FatalErrorType.NoSelectedIdsError });
        break;
      case "NoValidConfigurationsError":
        setErrorState({ code, message: FatalErrorType.NoValidConfigurationsError });
        break;
      case "NoUserValidConfigurationsError":
        setErrorState({ code, message: FatalErrorType.NoUserValidConfigurationsError });
        break;
      case "NoValidClientCredentialsError":
        setErrorState({ code, message: FatalErrorType.NoValidClientCredentialsError });
        break;
      case "NoValidVersionsError":
        setErrorState({ code, message: FatalErrorType.NoValidVersionsError });
        break;
      case "UnauthorizedError":
        setErrorState({ code, message: FatalErrorType.UnauthorizedError });
        break;
      case "UnsupportedEntityTypeError":
        setErrorState({ code, message: FatalErrorType.UnsupportedEntityTypeError });
        break;
      default:
        setErrorState({ code, message: FatalErrorType.Default });
    }
  });

  useEffect(() => {
    setErrorFromCode(props.errCode);
  }, [props.errCode]);


  const onGoToBluescape = () => {
    const bscUrl = `https://client.${getDomain()}/`;
    window.location.assign(bscUrl);
  };

  return (
    <div className={'Container_Error'}>
      <div className={'Error_wrapper'}>
        <div className={'Error_content'}>
          <img className={'Error_Logo'} src={require('../../assets/logo.png').default} alt="logo" />
          {(errorState?.code === 'UnauthorizedError') ?
                  <div className={'Error_Header'}>Redirecting to login</div>
                  :
                  <div className={'Error_Header'}>Upload unavailable</div>
                }
          <div className={'Error_Message'}>{errorState.message}</div>
          <Button className="Send_to_Bluescape_button" onClick={onGoToBluescape}>
            Go to Bluescape
          </Button>
        </div>
        <div className={'Error_icons'}>
          <img className={'fish_1'} src={require('../../assets/fish-big.svg').default} alt="logo" />
          <img className={'fish_2'} src={require('../../assets/fish-small.svg').default} alt="logo" />
          <img className={'fish_3'} src={require('../../assets/fish-small.svg').default} alt="logo" />
        </div>
      </div>
    </div>
  );
};
export default Error;

// user-friendly error mappings for workspace creation errors
export const workspaceCreateErrorMapping = ((code = "UnexpectedError" ) => {
  switch (code) {
    case "CreateWorkspaceAuthError":
      return 'User does not have permission to create workspaces in this organization.';
    case "NoDefaultRoleIdError":
      return "Organization must have a default workspace role id defined to complete this operation.";
    default:
      break;
  }
  return 'Unknown error occurred';
});

// user-friendly error mappings for upload failures
export const uploadFailErrorMapping = ((msg: string) => { 
  if (msg.includes("transcoding check found unsupported resolution")) {
    return 'Resolution is unsupported';
  } else if (msg.includes("job stalled") || (msg.includes("Promise timed out"))) {
    return "Upload failed due to a timeout";
  } else if (msg.includes("Error invoking elementary")) {
    return "An unknown error occurred during upload";
  } else if (msg.includes("Failed to fetch data")) {
    return "The URL for this file expired";
  } else {
    return msg;
  }
});

import React, { useEffect } from 'react'
import './progressContainer.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap'
import { getDomain } from '../../actions/api'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CanvasButton = (props: { wsId: string, transferId: string, data: any, loading: boolean }) => {

  const [canvasId, setCanvasId] = React.useState('');

  // Handles subscription events
  useEffect(() => {
    if (
      props.data?.commands?.commandType === "CreateElementCommand" &&
      props.data.commands.element?.elementType === "Canvas" &&
      props.data.commands.element.traits !== null
    ) {
      setCanvasId(props.data.commands.element.id);
    }
  }, [props.data, setCanvasId]);

  return (
    <Button
    disabled={props.loading || canvasId === ""}
    className="Send_to_Bluescape"
    onClick={()=>{
            const url = `https://client.${getDomain()}/${props.wsId}?objectId=${canvasId}`;
            window.open(url, "_blank");
        }}
    >
        Open in workspace
    </Button>
  )
}

export default CanvasButton;

export type Organizations = OrganizationsItem[];

export interface OrganizationsItem {
  name: string;
  uid: string;
  defaultRoleId: string; // default public workspace role id
}

export type Workspaces = WorkspaceData[];

export interface WorkspaceData {
  name: string;
  uid: string;
  defaultRoleId?: string;
}

export interface WorkspaceResults {
  results: WorkspaceInfoPayload[];
  nextCursor: string;
}

export interface Color {
  r: number,
  g: number,
  b: number,
  a: number,
}

export interface TransferInfoPayload {
  entityType: string;
  entityCount: number;
  orgId?: string;
  wsId?: string;
}

export interface QualifiedOrganizationInfoPayload {
  uid: string;
  name: string;
  defaultRoleId: string; // default public workspace role id
}

export interface TransferInfoForOrganizationPayload {
  entityName: string;
  imageCount: number;
  videoCount: number;
  unsupportedCount: number;
}

export interface WorkspaceInfoPayload {
  uid: string;
  name: string;
  canEdit: boolean;
  hasWorkspaces: boolean;
}

export interface IsamWorkspaceResponse {
  id: string;
  name: string;
  my: {
    permissions: string[];
  }
}

export interface IsamWorkspacesResponse {
  data: {
    me: {
      workspaces: {
        results: IsamWorkspaceResponse[]
        next?: string;
        prev?: string;
        totalItems: number;
      }
    }
  }
}
    
export interface InitiateTransferPayload {
  canvasName: string;
  canvasColor: Color;
  versionCount: number;
}

export interface RetryOrCancelTransferPayload {
  versionId: number;
  elementId?: string;
}

export interface CreateWorkspacePayload {
  workspaceName: string;
  defaultRoleId: string;
}

export interface LogPayload {
  logType: string;
  logMessage: string;
  organizationId?: string;
  versionId?: string;
  workspaceId?: string;
}

export interface TransferInfoForWorkspaceResponse {
  proposedCanvasColor?: Color;
  proposedCanvasName: string;
  previousCanvasCount: number;
  canSend: boolean;
}

export interface TransferItemInfo {
  name: string;
  id: string;
  supported: boolean;
}

export const mapStringToColor = (color: string) => {
  switch (color) {
    case 'CanvasColorTurtleSoft': return { r: 130, g: 222, b: 198, a: 1 };
    case 'CanvasColorCheeseSoft': return { r: 254, g: 240, b: 159, a: 1 };
    case 'CanvasColorTangerineSoft': return { r: 255, g: 195, b: 158, a: 1 };
    case 'CanvasColorSherbetSoft': return { r: 255, g: 161, b: 161, a: 1 };
    case 'CanvasColorPeonySoft': return { r: 255, g: 158, b: 230, a: 1 };
    case 'CanvasColorGrapeSoft': return { r: 221, g: 173, b: 255, a: 1 };
    case 'CanvasColorBluescape40':
    default:
      return { r: 153, g: 198, b: 255, a: 1 };
  }
}

export const mapColorToString = (c: Color) => {
  const flattenedColor = c ? `${c.r},${c.g},${c.b},${c.a}` : '153,198,255,1';
  switch (flattenedColor) {
    case '130,222,198,1': return 'CanvasColorTurtleSoft';
    case '254,240,159,1': return 'CanvasColorCheeseSoft';
    case '255,195,158,1': return 'CanvasColorTangerineSoft';
    case '255,161,161,1': return 'CanvasColorSherbetSoft';
    case '255,158,230,1': return 'CanvasColorPeonySoft';
    case '221,173,255,1': return 'CanvasColorGrapeSoft';
    case '153,198,255,1':
    default:
      return 'CanvasColorBluescape40';
  }
}

export enum FatalErrorType {
  MultipleEntities = 'Upload of multiple entities other than versions is not supported at this time. Please send one at a time.',
  TransferUnavailable = 'Unable to process the incoming data. Please try sending again.',
  NoOrganizations = 'No Bluescape organizations have been configured with access to ShotGrid.',
  NoSelectedIdsError = 'No entities were detected. Usually, this is because no entities were selected, or the upload was initiated from the Recents or Favorites sidebar of the Media page. If it was the latter, please send from the main Playlists page instead.',
  NoValidConfigurationsError = 'Contact your local ShotGrid administrator to ensure correct configurations.',
  NoUserValidConfigurationsError = 'This user does not have access to any Organizations that are configured for use with ShotGrid.',
  NoValidClientCredentialsError = `Failed to authenticate with the ShotGrid client credentials (client id and/or client password) that were provided in this Organization's ShotGrid configurations. Contact your local ShotGrid administrator to ensure correct configurations.`,
  NoValidVersionsError = 'The versions you are trying to upload are not supported by Bluescape',
  UnauthorizedError = 'Redirecting to login',
  UnsupportedEntityTypeError = 'We currently only support uploading of Assets, Playlists, Shots or Versions.',
  InvalidPayloadError = 'Invalid payload received from ShotGrid',
  Default = 'Unexpected error',
}

export enum LogTypes {
  UploadFailure = 'Upload Failure',
  UploadSuccess = 'Upload Success',
  Default = 'Frontend Error',
}
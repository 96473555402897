import './progressContainer.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const IconWrapper = (props: { id: string, state: string, retryFunc: (id: number) => Promise<void>, cancelFunc: (id: number) => Promise<void> }) => {
  switch (props.state) {
    case 'fatal':
      return <div><img src={require('../../assets/error.svg').default} alt="unrecoverable error" id={props.id} /></div>
    case 'error':
      return <div className="Progress_Icon"><img src={require('../../assets/refresh.svg').default} alt="retry" id={props.id} onClick={() => props.retryFunc(Number.parseInt(props.id))} role="button" /></div>
    case 'success':
      return <div><img src={require('../../assets/success.svg').default} alt="success" id={props.id} /></div>
    case 'waiting':
      return <></>
    case 'retrying':
      return <></>
    case 'inProgress':
    default:
      return <div className="Progress_Icon"><img src={require('../../assets/cross.svg').default} alt="in progress" id={props.id} onClick={() => props.cancelFunc(Number.parseInt(props.id))} role="button" /></div>
  }
}

export default IconWrapper;

import React, { useCallback, useEffect, useState } from 'react';
import './dropdown.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Dropdown, FormControl, InputGroup} from 'react-bootstrap'
import { AnyObject } from '@loopback/repository';
import { Organizations, OrganizationsItem, Workspaces, WorkspaceData} from '../../types/types';
import { Waypoint } from 'react-waypoint';
import { usePromiseTracker } from 'react-promise-tracker';

// TODOS:
// 1. Create Workspace: FormControl input validation? (e.g. user hasn't typed in a workspace name yet, workspace name too long, etc.)

interface Props {
    items: Organizations | Workspaces;
    action?: (eventKey: string | null, e: string, defaultRoleId?: string) => void;
    actionNextResults?: () => void;
    actionSearch?: (query: string) => void;
    actionResetWorkspaces?: () => void;
    actionCreateWorkspace?: (workspaceName: string) => void;
    placeholder: string;
    title: string;
    nextCursor: string;
    disabled?: boolean;
}

const debounceTime = 500;

const simpleUidArrayEquals = (list?: { uid: string }[], other?: { uid: string }[]): boolean => {
    if (list && other && list.length === other.length) {
        for (let i = 0; i < list.length; i++) {
            if (list[i].uid !== other[i].uid) {
                return false;
            }
        }
        return true;
    }
    // It is possible that list is null, while the other isn't (or vice versa)
    return list === other;
}

const DropdownMenu = (props: Props) => {
    const [searchValue, setSearchValue] = useState('');
    const [createWorkspaceValue, setCreateWorkspaceValue] = useState('');
    const [selected, setSelected] = React.useState('');
    const [items, setItems] = React.useState(
        [
            {
                uid: '',
            }
        ]
    );

    const handleSelect = useCallback((eventKey: string | null) => {
        const value = props.items.find((obj: OrganizationsItem | WorkspaceData) => {
            return obj.uid === eventKey;
        });
        if (value && value.uid !== "NOWORKSPACES") {
            setSelected(value.name);
            if (props.action) {
                if (value.defaultRoleId) {
                    props.action(eventKey, value.name, value.defaultRoleId); // Here is where we call the function that was passed to us from the App component
                } else {
                    props.action(eventKey, value.name);
                }
            }
        }
    }, [props])

    const getNextResults = () => {
        if (props.actionNextResults && props.nextCursor && props.nextCursor !== "") {
            props.actionNextResults(); // Here is where we call the function that was passed to us from the App component
        }
    }

    useEffect(() => {
        // If the workspace/org array has changed, reset selected
        if (!simpleUidArrayEquals(items, props.items)) {
            setSelected('');
            setItems(props.items.map((i: { uid: string }) => { return { uid: i.uid } }));
        }

        // If there is only a single item in the list, autoselect that
        if (
            (!selected || selected === "") &&
            props.items &&
            props.items.length === 1 &&
            props.items[0].uid !== ""
        ) {
            handleSelect(props.items[0].uid);
        }
    }, [handleSelect, props.items, selected, items]);

    // Triggers when user searches for item. Run query to get results based on user's search param
    useEffect(() => {  
        const delayDebounceFn = setTimeout(() => { // Delay the search for user's provided search value until they have stopped typing for debounceTime seconds
            if (props.actionSearch && searchValue && searchValue !== "" && searchValue !== props.placeholder) { // run query to get results based on user's search
                props.actionSearch(searchValue);
            } else if (props.actionResetWorkspaces && (!searchValue || searchValue === "")) { // if search field gets reset to empty by user, reinitialize the dropdown list
                props.actionResetWorkspaces();
            }
        }, debounceTime)
        return () => clearTimeout(delayDebounceFn);
    }, [searchValue]);

    // Triggers from button click on "Create Workspace"
    const createWorkspace = () => {
        if (createWorkspaceValue && createWorkspaceValue !== '' && props.actionCreateWorkspace) { 
            handleSetShowFalse(); // stop displaying Create Workspace Form after user has created workspace 
            props.actionCreateWorkspace(createWorkspaceValue); 
            setSelected(createWorkspaceValue); // select workspace after creation
            setCreateWorkspaceValue(''); // reset "Create new workspace" text field after workspace is created
        }
    }

    // Handle display "Create Workspace" button and form
    const [showCreateButton, setShowCreateButton] = React.useState(false);
    const handleSetShowFalse = () => setShowCreateButton(false);
    const handleSetShowTrue = () => setShowCreateButton(true);

    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    const isDisabled = !props || props.disabled || !props.items;

    // Block getting more results from dropdowns
    const { promiseInProgress: blockDropdownInteraction } = usePromiseTracker({delay: 250});

    return (
        <div className="Dropdown_Container">
            <label
                htmlFor="label"
                className="Input_Title"
            >
                {props.title}
            </label>
            <Dropdown className="Dropdown_Button"
                id="dropdown-size-medium"
                onSelect={handleSelect}
            >
                <Dropdown.Toggle disabled={isDisabled} >
                    <div className="dropdown-item_name">
                        {selected !== "" ? selected : props.placeholder}
                    </div>
                </Dropdown.Toggle>
                {!isDisabled &&
                    <Dropdown.Menu>
                        <FormControl
                            autoFocus
                            className="dropdownInput"
                            placeholder="Type to search..."
                            onChange={(e) => setSearchValue(e.target.value)}
                            value={searchValue}
                            disabled={blockDropdownInteraction}
                        />
                        {props.title === "WORKSPACE" && !showCreateButton &&
                            <button className="dropdown-item_create" onClick={handleSetShowTrue}>
                                Create new workspace
                            </button>
                        }
                        {props.title === "WORKSPACE" && showCreateButton &&
                            <InputGroup>
                                <FormControl
                                    autoFocus
                                    className="dropdownInput"
                                    placeholder="Type new workspace name..."
                                    onChange={(e) => setCreateWorkspaceValue(e.target.value)}
                                    value={createWorkspaceValue}
                                    disabled={blockDropdownInteraction}
                                    onKeyPress={(event: { key: string; }) => {
                                        if (event.key === "Enter") {
                                          createWorkspace();
                                        }
                                      }}
                                >
                                </FormControl>
                                <InputGroup.Append>
                                    <div className="dropdown-item_add" onClick={createWorkspace}>
                                        <img src={require('../../assets/add.svg').default} alt="add"/>
                                    </div>
                                </InputGroup.Append>
                            </InputGroup>
                        }
                        {props.title === "WORKSPACE" ? props.items.map((item: AnyObject, index: number) => (
                            <Dropdown.Item
                                className="Dropdown_Item"
                                eventKey={item.uid}
                                key={`Dropdown_Item${index}`}
                                disabled={(item && item.canEdit === false)}
                            >
                                <div className="dropdown-item_text">
                                {item.name}
                                </div>
                                <div className="dropdown-item_hint">
                                {
                                (item && (item.canEdit === false) && item.hasWorkspaces) &&
                                "No edit access"
                                }
                                </div>
                            </Dropdown.Item>
                        ))
                        :
                        props.items.filter((item) =>
                            !searchValue || (item.name.toLowerCase().search(searchValue.toLowerCase()) > -1),
                        ).map((item: AnyObject, index: number) => (
                            <Dropdown.Item
                                className="Dropdown_Item"
                                eventKey={item.uid}
                                key={`Dropdown_Item${index}`}
                                disabled={(item && item.canEdit === false)}
                            >
                                <div className="dropdown-item_text">
                                {item.name}
                                </div>
                                <div className="dropdown-item_hint">
                                {
                                (item && (item.canEdit === false) && item.hasWorkspaces) &&
                                "No edit access"
                                }
                                </div>
                            </Dropdown.Item>
                        ))}
                        <Waypoint onEnter={blockDropdownInteraction ? undefined : getNextResults} fireOnRapidScroll={false} />
                    </Dropdown.Menu>
                }
            </Dropdown>
        </div>
    )
}

export default DropdownMenu;
